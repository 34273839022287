.owl-carousel .owl-stage-outer .owl-stage .owl-item .item .nft-content {
  transform: translateY(100%);
  background-color: #1305238e;
  transition: transform 0.4s ease-in-out;
  color: #fff;
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
}

.owl-carousel .owl-stage-outer .owl-stage .owl-item .item:hover .nft-content {
  transform: translateY(0%);
}

.owl-carousel .owl-stage-outer .owl-stage .owl-item .item .nft-content .title {
  font-weight: bold;
  margin: 0.4rem 0;
  font-size: 1.2rem;
}
