.home .section-container .home-paragraph {
  color: rgba(255, 255, 255, 0.57);
  position: relative;
  z-index: 1;
  font-size: 1.3rem;
}

.home .section-container {
  height: 100vh !important;
}

.home .section-container .home-button {
  background: none;
  border: none;
  margin: 1rem 1rem 1rem 0;
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
  user-select: none;
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #5139d7 15%,
    #e10aca 100%
  );
  font-family: "Poppins", sans-serif;
  color: #fff;
  padding: 20px 40px;
  font-weight: bold;
  border-radius: 7px;
  /* -webkit-box-shadow: 0 -1px 4px, #5139d7 0 -2px 10px, #5139d7 0 -10px 20px,
      #e10aca 0 -18px 40px, 5px 5px 15px 5px rgba(0, 0, 0, 0); */

  box-shadow: #4158d0 10px -2px 110px, #5139d7 0 -10px 20px,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
}

.home .section-container .connect-mask {
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  color: #fff;
  padding: 20px 40px;
  font-weight: bold;
  border-radius: 7px;
  background-color: #130523;
  border: none;
}

.home .section-container .right-section img {
  position: absolute;
  top: 90px;
  right: 0;
  width: 50%;
  height: auto;
  z-index: 2;
  object-fit: cover;
  pointer-events: none;
}

.home::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to top, #130523, transparent);
  z-index: 200;
}

.home {
  position: relative;
  /* display: flex; */
  height: 100vh;
  width: 100%;
  background-size: cover;
  overflow: hidden;
  /* background: #130523; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  background-color: #4c0752;
  background-image: linear-gradient(
    33deg,
    #4c0752 0%,
    #4c0752 14%,
    #130523 50% /*54 veya 83 dene*/
  );

  /* background-image: url("https://wallpaperaccess.com/full/188825.jpg"); */
  background-position: bottom;
  /* justify-content: center;
    align-items: center; */
}

.home .section-container .section-title {
  font-size: 7em !important;
  color: #fff;

  font-family: "Poppins", sans-serif;
  z-index: 1;
  margin-bottom: 10%;
  position: relative;
}

/* .home::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 200;
  mix-blend-mode: color;
} */

@media only screen and (max-width: 1024px) and (min-device-width: 280px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .home .section-container .rigth-section .surface {
    display: none !important;
  }
  .home .section-container .rigth-section .surface {
    display: none !important;
  }

  .home .section-container .left-section .home-paragraph {
    width: 70%;
    margin: 1rem auto;
  }

  .home .section-container .left-section button {
    width: 80%;
    margin: 1rem auto;
  }
}
