/* .focus::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to top, #130523, transparent) !important;
  z-index: 200;
} */

/* .focus::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 00;
  mix-blend-mode: color;
} */

.focus {
  overflow: hidden;
  /* background-color: #130523 !important; */
}
.focus img {
  width: 800px;
  margin: 0 auto;
  height: 800px;
}
