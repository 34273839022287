.section .section-container .human-cards {
  display: grid;
  grid-template-columns: repeat(4, 200px);
  grid-gap: 50px;
  margin: 2rem 0;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.section .section-container .human-cards .human-card {
  background-color: #1c184c;
  padding: 2rem;
  transition: all 0.8s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.section .section-container .human-cards .human-card:hover {
  background-color: #fff;
  color: #1c184c;
  transform: scale(1.2);
}

.section .section-container .human-cards .human-card:hover .human-card-icon {
  border: 1px solid #1c184c;
}

.section .section-container .human-cards .human-card .human-card-icon {
  border-radius: 100%;
  padding: 10px;
  border: 1px solid #fff;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin: 0 auto 1.2rem auto;
}

.section .section-container .human-cards .human-card .human-card-title {
  font-weight: bold;
  margin: 1rem 0;
}

.section .section-container .human-cards .human-card .human-card-list {
  margin: 1rem 0;
}

.section .section-container .human-cards .human-card .human-card-list-item {
  font-size: 0.8rem;
  margin: 0.8rem 0;
}

@media only screen and (max-width: 1024px) and (min-device-width: 280px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .section .section-container .human-cards {
    grid-template-columns: repeat(1, 90%);
  }
}
