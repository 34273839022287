.section .section-container .footer-title {
  font-family: "Saira Stencil One", cursive;
  text-align: center !important;
  margin: 2rem 0;
}

.section.section-footer {
  margin-top: 8rem;
}

.section.section-footer video {
  display: block;
}

.section.section-footer .bottom-section {
  padding: 4rem 0;
  background-color: #e89f4d3f;
  /* background-image: url("../images/orange-surface.png"); */
  margin-top: 8rem;
  border-top: 2px solid rgba(255, 255, 255, 0.193);
}

.section.section-footer .bottom-section .footer-section-container {
  max-width: 1600px;
  margin: 0 auto;
}

.section.section-footer .bottom-section .section-container {
  display: flex;
}

.section.section-footer .bottom-section .section-container .left-footer {
  padding: 2rem 0;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top {
  display: flex;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .left-footer-block {
  width: 60%;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block {
  width: 40%;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .top-section-footer-right {
  display: flex;
}
.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .top-section-footer-right
  .help-section {
  align-self: center;
  margin-left: 2rem;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .top-section-footer-right
  .help-section
  .title {
  font-size: 20px;
  border-left: 4px solid #fff;
  padding: 0.3rem;
  padding-left: 0.2rem;
  margin-bottom: 1rem;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .top-section-footer-right
  .help-section
  a {
  text-align: center;
  color: #fff;
  margin-left: 2rem;
  font-weight: bold;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .website-logo {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  height: 150px;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .website-logo
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .right-footer-blocks {
  margin: 1rem 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: flex-start;
  grid-gap: 30px;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .right-footer-blocks
  .footer-block
  .footer-link {
  margin: 0.8rem;
}

section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .right-footer-blocks
  .footer-block
  .footer-link
  a {
  color: #fff;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .right-footer-blocks {
  display: flex;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .footer-block-list {
  font-weight: bold;

  margin: 2rem 0;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .footer-block-list
  .footer-block-list-title {
  font-weight: bold;
  margin: 0.8rem 0;
}
.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .footer-block-list
  li {
  font-size: 14px;
  line-height: 1.6rem;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.686);
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-bottom {
  display: flex;
  height: 100%;
  margin-top: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-bottom
  .social-links {
  display: flex;

  align-items: center;
  margin: 1rem auto;

  justify-content: space-around;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-bottom
  .copyright {
  font-size: 12px;
  text-align: center;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-bottom
  .social-links
  .social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 0.8rem;
  background-color: #fff;
  color: #130523;
  width: 20px;
  margin: 0 0.6rem;
  height: 20px;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-bottom
  .social-links
  .social-link
  a {
  background-color: #fff;
  color: #130523;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-bottom
  .social-links
  .social-link
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .left-footer-block
  .footer-block-title {
  font-size: 2.2rem;
  font-weight: bold;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .left-footer-block
  .footer-block-title.play-title {
  font-size: 2.2rem;
  font-weight: bold;
  margin: 1rem 0;
  font-family: "Saira Stencil One", cursive;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .left-footer-block
  .footer-block-paragraph {
  line-height: 1.6rem;
  margin: 1rem 0;
  text-align: justify;
  color: rgba(255, 255, 255, 0.686);
  width: 69%;
  font-size: 14px;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .left-footer-block
  .footer-block-list {
  margin: 2rem 0;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .left-footer-block
  .footer-block-list
  .footer-block-list-title {
  font-weight: bold;
  margin: 0.8rem 0;
}
.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .left-footer-block
  .footer-block-list
  li {
  line-height: 1.6rem;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.686);
}

@media only screen and (max-width: 1366px) {
  .section.section-footer .bottom-section {
    padding: 2rem 1rem;
  }
}

@media only screen and (max-width: 1024px) and (min-device-width: 280px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top {
    flex-direction: column;
  }

  .section.section-footer {
    margin-top: 0 !important;
  }

  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top
    .left-footer-block {
    width: 100%;
  }

  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top
    .right-footer-block {
    width: 100%;
  }

  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top
    .left-footer-block
    .footer-block-paragraph {
    width: 100%;
  }

  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top
    .right-footer-block
    .top-section-footer-right {
    flex-direction: column;
  }

  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top
    .right-footer-block
    .top-section-footer-right
    .help-section {
    justify-content: center;
    margin: 1rem auto;
  }

  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top
    .right-footer-block
    .website-logo {
    margin: 0 auto;
  }

  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top
    .left-footer-block
    .footer-block-title.play-title {
    text-align: center;
    font-size: 1.5rem;
    margin: 2rem 0;
  }
}
