@import url("https://fonts.googleapis.com/css2?family=Stalinist+One&display=swap");
.navbar {
  position: fixed;
  color: #fff;
  top: 0;
  padding: 10px;
  z-index: 302;
  left: 0;
  right: 0;
}

.navbar-container {
  max-width: 1600px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.navbar-logo {
  font-size: 1.2rem;
  align-self: center;
  cursor: pointer;
}

.sidebar {
  display: flex;
  bottom: 0;
  position: fixed;
  width: 60%;
  height: 100vh;
  align-items: center;
  top: 0;
  z-index: -1 !important;
  right: 0;
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 0 100%);
  background-color: rgba(19, 5, 35, 1);
  justify-content: center;
}

.navbar .overlay {
  position: fixed;
  width: 70%;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  top: 0;
  right: 0;
  clip-path: polygon(100% 0, 0 0, 0 100%, 57.2% 100%);
  z-index: -1;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.navbar .overlay .logo-container {
  background-color: #fff;
  border-radius: 100%;
  padding: 1rem;
  width: 200px;

  background-color: "#4158d0";
  background-image: "linear-gradient(43deg,#FF5A00 0%,#FF5A00 15%,#FF5A00 100%)";
  border-radius: 100%;
  box-shadow: #fff 10px -2px 110px, #fff 0 -10px 20px,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  height: 200px;
}

.navbar .overlay .overlay-title {
  margin: 1rem 0;
}

.navbar .overlay .logo-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.hamburger-menu-list {
  list-style: none;
  display: flex;
  justify-content: flex-start;

  flex-direction: column;
}

.hamburger-menu-list-item {
  cursor: pointer;
  color: #fff;
  padding: none;
  font-size: 3rem;
  text-align: left;
}

li {
  margin-bottom: 10px;
}

.hamburger-menu-list-item::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: rgb(233, 233, 233);
  transition: width 0.3s;
}

.hamburger-menu-list-item:hover::after {
  width: 100%;
}

.navbar-active {
  background-color: #1305237e;
}

.navbar-disable {
  border: none;
  background-color: transparent;
}

@media only screen and (max-width: 1024px) and (min-device-width: 280px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .sidebar {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    width: 100%;
  }
  .navbar .overlay {
    display: none !important;
  }
}
