@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
.mint-section {
  background-color: #2b6688;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1rem;
}

.amount {
  font-family: "Stalinist One", cursive;
  font-size: 2.5rem;
  color: #fff;
  text-align: center;
}

.contract {
  text-align: center;
  margin: 1rem 0;
  color: #fff;
}

.contract .contract-title {
  font-weight: bold;
  margin: 0.6rem 0;
}

.contract .contract-link a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.549);
}

.pricing {
  margin: 5rem 0;
  text-align: center;
  color: #ffff;
  font-size: 3rem;
  /*font-family: "Stalinist One", cursive;*/
}

.pricing .item-amount {
  color: #19113d;
  font-family: "Press Start 2P", cursive;
}

.pricing .cost {
  color: #19113d;
  font-family: "Press Start 2P", cursive;
}

.pricing .pricing-note {
  font-size: 15px;
  margin: 0.2rem 0;
  font-family: "Press Start 2P", cursive;
}

.connection {
  text-align: center;
}

.connection .connection-title {
  color: #fff;
  font-size: 20px;
}

.connection .connection-title span {
  font-weight: 900;
  color: #19113d;
}

.connection .connect-btn {
  padding: 1rem 1.5rem;
  margin: 1.4rem 0;
  border-radius: 10px;
  border: none;
  background: none;
  cursor: pointer;
  background-color: #19113d;
  color: #fff;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  padding: 1rem;
}

.error .error-message {
  color: #eb252a;
}

.brief-informations {
  margin: 3rem 0;
  display: grid;
  grid-template-columns: repeat(3, 400px);
  grid-gap: 30px;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
}

.brief-informations .information {
  background-color: #fff;
  padding: 1rem;
  background-image: url("../images/wave.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100%;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 24px 50px rgb(0 0 0 / 15%);
}

.brief-informations .information .info-t {
  margin: 0 auto;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  color: #19113d;
  font-weight: bold;
}

.brief-informations .information .info-p {
  color: #19113d;
  width: 70%;
  margin: 0 auto;
  line-height: 1.6rem;
}

.brief-informations .information .info-logo {
  margin: 0 auto;
  width: 80px;
  height: 80px;
  margin-bottom: 2rem;
}

.brief-informations .information .info-logo i {
  margin: 0 auto;
  font-size: 4rem;
  margin-bottom: 2rem;
  color: #19113d;
}

.brief-informations .information .info-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feedback {
  margin: 1rem 0;
  color: #fff;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: center;
}

.buttons .mint-amount {
  font-family: "Press Start 2P", cursive;
  background-color: #2b6688;
  align-self: center;
  font-size: 3rem;
  color: #fff;
}

.buttons .increase-btn {
  padding: 0.6rem 1rem;
  margin: 1.4rem 0;
  border-radius: 10px;
  width: 50px;
  font-family: "Press Start 2P", cursive;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  border: none;
  margin-left: 1rem;
  background: none;
  cursor: pointer;
  background-color: transparent;
  border: 4px solid #19113d;
  color: #19113d;
  font-size: 1.5rem;
}

.buttons .decrease-btn {
  padding: 0.6rem 1rem;
  margin: 1.4rem 0;
  font-family: "Press Start 2P", cursive;
  border-radius: 10px;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  border: none;
  margin-right: 1rem;
  background: none;
  cursor: pointer;
  background-color: transparent;
  border: 4px solid #19113d;
  color: #19113d;
  font-size: 1.5rem;
}

.buy-btn {
  padding: 1rem 1.5rem;
  margin: 1.4rem 0;
  border-radius: 10px;
  font-family: "Press Start 2P", cursive;
  border: none;
  background: none;
  cursor: pointer;
  background-color: #19113d;
  color: #fff;
  box-shadow: 0 24px 50px rgb(0 0 0 / 15%);
}

@media only screen and (max-width: 1024px) and (min-device-width: 280px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .brief-informations {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 80px;
  }
  .brief-informations .information {
    background-image: none;
  }
  .pricing {
    font-size: 2em;
  }
}
