.section.introduction {
  background-image: url("../images/blue.png") !important;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.section.introduction::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to top, #2b6789, transparent) !important;
  z-index: 200;
}
