@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Stalinist+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Stalinist+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee+Shade&family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Saira+Stencil+One&display=swap);
ul {
  padding: 0 !important;
}

* {
  padding: 0;
  margin: 0;
}

.section {
  height: 100vh;
  position: relative;
  color: #fff;
}

.section > img,
section .section-container img,
.section
  .section-container
  .top-section
  img
  .section
  .section-container.right-section
  img {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.section.light {
  background-color: #fff;
  color: #130523;
}

.section.light .section-container .left-section .section-title {
  color: #130523 !important;
}

.section .circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  padding: 3px;
  position: absolute;
  top: 0;

  margin: 1rem auto;
}

.section .circle.dark {
  background-color: #1c184c;
}
.section.circle.light {
  background-color: #fff;
}

.section-free {
  height: 100% !important;
  padding-bottom: 2rem;
  position: relative;
}

.section .section .section-title {
  font-size: 4rem;
  text-align: center;
  color: #fff;
}

.section .section-container {
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

.section .section-container .section-title {
  color: #fff !important;
}

.dark-section {
  background-color: #130523;
  color: #fff;
  position: relative;
}

.section .section-container .left-section {
  align-self: center;
}

.section .section-container .section-title {
  font-size: 4rem;
  color: #1c184c;
  text-align: left;
}
.activity-section .section-title {
  font-size: 4rem;
}
.dark-section .section-container .section-title {
  color: #fff;
  text-align: left;
}
.section .section-container .section-paragraph {
  font-size: 1.2rem;
  width: 700px;
  line-height: 2rem;
  margin: 1rem 0;
  text-align: justify;
}

.section .section-container .section-button {
  background-color: #ee792c;
  color: #fff;
  border: none;
  border-radius: 23px;
  font-size: 1rem;
  padding: 10px 25px;
  display: block;
  float: left;
  cursor: pointer;
  margin: 2rem 0;
  text-decoration: none;
}

.section-column .section-container {
  flex-direction: column;
}

.section-column .planet-image {
  display: flex;
  justify-content: end;
  width: 700px;
  height: 700px;
}

@media only screen and (max-width: 1366px) {
  body {
    overflow-x: hidden;
  }
  .section {
    height: 100vh;
    /* padding: 1rem; */
  }
  .section .section-container {
    padding: 1rem;
  }
  .home .section-container .left-section .section-title {
    font-size: 5em !important;
  }
  .home .section-container .left-section .home-paragraph {
    font-size: 1rem !important;
    width: 400px;
    color: rgba(255, 255, 255, 0.57);
  }
}

@media only screen and (max-width: 1024px) and (min-device-width: 280px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .section {
    height: 100%;
    /* padding: 1rem; */
  }

  .section .section-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    grid-row-gap: 30px;
  }

  .section.introduction .section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .section.introduction .section-container .left-section {
    margin-bottom: 20rem;
  }

  .section .section-container .left-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .section .section-container .section-title {
    text-align: center;
    font-size: 2rem !important;
  }

  .home {
    height: 100% !important;
  }
  .home .section-container .left-section .section-title {
    font-size: 2rem !important;
    margin-bottom: 1rem;
  }

  .dark-section .section-container .section-title {
    text-align: center;
  }

  .activity-section .section-title {
    font-size: 2rem;
  }

  .section .section-container .saturn-image {
    width: 70%;
    height: auto;
  }

  .section-title.road-map {
    margin-top: 4rem;
    font-size: 2rem !important;
  }

  .section .section-container .section-paragraph {
    text-align: center;
    margin: 1rem auto;
    width: 90%;
  }
  .section .section-container .section-button {
    margin: 0 auto;
  }
  .section .section-container .planet-image {
    width: 79%;
    margin: 0 auto;
    height: auto;
  }

  .section.home .section-container {
    margin-top: 20%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .section-column .section-container .planet-image {
    width: 100%;
    margin: 0 auto;
    height: auto;
  }

  .section .section-container .top-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .section .section-container .planet-image {
    display: none !important;
  }
}

.navbar {
  position: fixed;
  color: #fff;
  top: 0;
  padding: 10px;
  z-index: 302;
  left: 0;
  right: 0;
}

.navbar-container {
  max-width: 1600px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.navbar-logo {
  font-size: 1.2rem;
  align-self: center;
  cursor: pointer;
}

.sidebar {
  display: flex;
  bottom: 0;
  position: fixed;
  width: 60%;
  height: 100vh;
  align-items: center;
  top: 0;
  z-index: -1 !important;
  right: 0;
  -webkit-clip-path: polygon(50% 0, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(50% 0, 100% 0, 100% 100%, 0 100%);
  background-color: rgba(19, 5, 35, 1);
  justify-content: center;
}

.navbar .overlay {
  position: fixed;
  width: 70%;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  top: 0;
  right: 0;
  -webkit-clip-path: polygon(100% 0, 0 0, 0 100%, 57.2% 100%);
          clip-path: polygon(100% 0, 0 0, 0 100%, 57.2% 100%);
  z-index: -1;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.navbar .overlay .logo-container {
  background-color: #fff;
  border-radius: 100%;
  padding: 1rem;
  width: 200px;

  background-color: "#4158d0";
  background-image: "linear-gradient(43deg,#FF5A00 0%,#FF5A00 15%,#FF5A00 100%)";
  border-radius: 100%;
  box-shadow: #fff 10px -2px 110px, #fff 0 -10px 20px,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  height: 200px;
}

.navbar .overlay .overlay-title {
  margin: 1rem 0;
}

.navbar .overlay .logo-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.hamburger-menu-list {
  list-style: none;
  display: flex;
  justify-content: flex-start;

  flex-direction: column;
}

.hamburger-menu-list-item {
  cursor: pointer;
  color: #fff;
  padding: none;
  font-size: 3rem;
  text-align: left;
}

li {
  margin-bottom: 10px;
}

.hamburger-menu-list-item::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: rgb(233, 233, 233);
  transition: width 0.3s;
}

.hamburger-menu-list-item:hover::after {
  width: 100%;
}

.navbar-active {
  background-color: #1305237e;
}

.navbar-disable {
  border: none;
  background-color: transparent;
}

@media only screen and (max-width: 1024px) and (min-device-width: 280px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .sidebar {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    width: 100%;
  }
  .navbar .overlay {
    display: none !important;
  }
}

.home .section-container .home-paragraph {
  color: rgba(255, 255, 255, 0.57);
  position: relative;
  z-index: 1;
  font-size: 1.3rem;
}

.home .section-container {
  height: 100vh !important;
}

.home .section-container .home-button {
  background: none;
  border: none;
  margin: 1rem 1rem 1rem 0;
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #5139d7 15%,
    #e10aca 100%
  );
  font-family: "Poppins", sans-serif;
  color: #fff;
  padding: 20px 40px;
  font-weight: bold;
  border-radius: 7px;
  /* -webkit-box-shadow: 0 -1px 4px, #5139d7 0 -2px 10px, #5139d7 0 -10px 20px,
      #e10aca 0 -18px 40px, 5px 5px 15px 5px rgba(0, 0, 0, 0); */

  box-shadow: #4158d0 10px -2px 110px, #5139d7 0 -10px 20px,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
}

.home .section-container .connect-mask {
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  color: #fff;
  padding: 20px 40px;
  font-weight: bold;
  border-radius: 7px;
  background-color: #130523;
  border: none;
}

.home .section-container .right-section img {
  position: absolute;
  top: 90px;
  right: 0;
  width: 50%;
  height: auto;
  z-index: 2;
  object-fit: cover;
  pointer-events: none;
}

.home::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to top, #130523, transparent);
  z-index: 200;
}

.home {
  position: relative;
  /* display: flex; */
  height: 100vh;
  width: 100%;
  background-size: cover;
  overflow: hidden;
  /* background: #130523; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  background-color: #4c0752;
  background-image: linear-gradient(
    33deg,
    #4c0752 0%,
    #4c0752 14%,
    #130523 50% /*54 veya 83 dene*/
  );

  /* background-image: url("https://wallpaperaccess.com/full/188825.jpg"); */
  background-position: bottom;
  /* justify-content: center;
    align-items: center; */
}

.home .section-container .section-title {
  font-size: 7em !important;
  color: #fff;

  font-family: "Poppins", sans-serif;
  z-index: 1;
  margin-bottom: 10%;
  position: relative;
}

/* .home::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 200;
  mix-blend-mode: color;
} */

@media only screen and (max-width: 1024px) and (min-device-width: 280px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .home .section-container .rigth-section .surface {
    display: none !important;
  }
  .home .section-container .rigth-section .surface {
    display: none !important;
  }

  .home .section-container .left-section .home-paragraph {
    width: 70%;
    margin: 1rem auto;
  }

  .home .section-container .left-section button {
    width: 80%;
    margin: 1rem auto;
  }
}

/* .focus::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to top, #130523, transparent) !important;
  z-index: 200;
} */

/* .focus::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 00;
  mix-blend-mode: color;
} */

.focus {
  overflow: hidden;
  /* background-color: #130523 !important; */
}
.focus img {
  width: 800px;
  margin: 0 auto;
  height: 800px;
}

.section.introduction {
  background-image: url(/static/media/blue.5d10008d.png) !important;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.section.introduction::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to top, #2b6789, transparent) !important;
  z-index: 200;
}

.activity-section {
  height: 100% !important;
  padding: 2rem 0;

  background: rgb(43, 103, 137);
  background: linear-gradient(
    180deg,
    rgba(43, 103, 137, 1) 0%,
    rgba(28, 24, 76, 1) 72%,
    rgba(28, 24, 76, 1) 85%,
    rgba(28, 24, 76, 1) 100%
  );
}

.activity-section .nft-section {
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-template-columns: repeat(4, 1fr);
  margin: 4rem 0;
}

.activity-section .responsiveCarousel {
  display: none;
}

.activity-section .section-title {
  text-align: center;
  font-weight: bold;
}

.activity-section .nft-section .nft-block {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.activity-section .nft-section .nft-block:hover .nft-content {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.activity-section .nft-section .nft-block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.activity-section .nft-section .nft-block .nft-content {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  background-color: #1305238e;
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  color: #fff;
  text-align: center;
  position: absolute;
  bottom: 0;
  padding: 1rem;
}

.activity-section .nft-title-number {
  font-weight: bold;
  font-family: "Stalinist One", cursive;
  font-size: 4rem;
}

.activity-section .nft-section .nft-block .nft-content .title {
  font-weight: bold;
  margin: 2rem 0;
  font-size: 1.2rem;
}

@media only screen and (max-width: 1024px) and (min-device-width: 280px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .activity-section .responsiveCarousel {
    display: block;
  }
  .activity-section .owl-theme {
    display: none !important;
  }

  .carousel .control-dots {
    display: none !important;
  }
  .section .nft-title-number {
    font-size: 3rem;
  }

  .section .nft-content {
    padding: 1rem;
    margin-top: 2rem;
  }

  .section .nft-content .title {
    margin: 1rem 0;
    font-weight: bold;

    font-size: 1.7rem;
  }
  .section .nft-content .description {
    width: 80%;
    text-align: justify;
    margin: 1rem auto;
    line-height: 1.5rem;
  }
  .carousel .thumbs-wrapper {
    margin: 40px 0;
    overflow: hidden;
  }
}

.owl-carousel .owl-stage-outer .owl-stage .owl-item .item .nft-content {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  background-color: #1305238e;
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  color: #fff;
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
}

.owl-carousel .owl-stage-outer .owl-stage .owl-item .item:hover .nft-content {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.owl-carousel .owl-stage-outer .owl-stage .owl-item .item .nft-content .title {
  font-weight: bold;
  margin: 0.4rem 0;
  font-size: 1.2rem;
}

.section.earth-section {
  background: rgb(19, 5, 35);
  background: linear-gradient(
    360deg,
    rgba(19, 5, 35, 1) 0%,
    rgba(19, 5, 35, 1) 0%,
    rgba(28, 24, 76, 1) 65%
  );
}
.section-title.road-map {
  margin-top: 4rem;
  font-size: 2rem !important;
}

.timeline {
  position: relative;
  margin: 50px auto;
  padding: 40px 0;
  width: 1000px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.section-title {
  text-align: center;
  font-weight: bold;
  font-size: 5em;
  color: #fff;
}

.timeline:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 2px;
  height: 100%;
  background: #c5c5c5;
}

.timeline ul {
  padding: 0;
  margin: 0;
}

.timeline ul li {
  list-style: none;
  position: relative;
  width: 50%;
  padding: 20px 40px;
  box-sizing: border-box;
}

.timeline ul li:nth-child(odd) {
  float: left;
  text-align: right;
  clear: both;
}

.timeline ul li:nth-child(even) {
  float: right;
  text-align: left;
  clear: both;
}

.content {
  padding-bottom: 20px;
}

.timeline ul li .content .content-list {
  margin: 1rem 0;
}

.timeline ul li .content .content-list div {
  margin: 1rem 0;
  color: #fff;
}

.timeline ul li:nth-child(odd):before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 24px;
  right: -6px;
  background: #1c184c;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(56, 36, 205, 0.3);
}

.timeline ul li:nth-child(even):before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 24px;
  left: -4px;
  background: #1c184c;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(56, 36, 205, 0.3);
}

.timeline ul li h3 {
  margin: 0;
  padding: 8px 16px;
  text-align: center;
  background: #1c184c;
  color: #fff;
  border-radius: 18px;
  box-shadow: 0 0 0 3px rgba(56, 36, 205, 0.3);
}

.timeline ul li p {
  margin: 10px 0 0;
  padding: 0;
}

.timeline ul li .time h4 {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.timeline ul li:nth-child(odd) .time {
  position: absolute;
  top: 12px;
  right: -165px;
  margin: 0;
  padding: 8px 16px;
  background: #1c184c;
  color: #fff;
  border-radius: 18px;
  box-shadow: 0 0 0 3px rgba(56, 36, 205, 0.3);
}

.timeline ul li:nth-child(even) .time {
  position: absolute;
  top: 12px;
  left: -165px;
  margin: 0;
  padding: 8px 16px;
  background: #1c184c;
  color: #fff;
  border-radius: 18px;
  box-shadow: 0 0 0 3px rgba(56, 36, 205, 0.3);
}

@media (max-width: 1000px) {
  .timeline {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .timeline {
    width: 100%;
    padding-bottom: 0;
  }
  h1 {
    font-size: 40px;
    text-align: center;
  }
  .timeline:before {
    left: 20px;
    height: 100%;
  }
  .timeline ul li:nth-child(odd),
  .timeline ul li:nth-child(even) {
    width: 100%;
    text-align: left;
    padding-left: 50px;
    padding-bottom: 50px;
  }
  .timeline ul li:nth-child(odd):before,
  .timeline ul li:nth-child(even):before {
    top: -18px;
    left: 16px;
  }
  .timeline ul li:nth-child(odd) .time,
  .timeline ul li:nth-child(even) .time {
    top: -30px;
    left: 50px;
    right: inherit;
  }
}
/*# sourceMappingURL=roadmap.css.map */

.section .section-container .human-cards {
  display: grid;
  grid-template-columns: repeat(4, 200px);
  grid-gap: 50px;
  margin: 2rem 0;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.section .section-container .human-cards .human-card {
  background-color: #1c184c;
  padding: 2rem;
  transition: all 0.8s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.section .section-container .human-cards .human-card:hover {
  background-color: #fff;
  color: #1c184c;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.section .section-container .human-cards .human-card:hover .human-card-icon {
  border: 1px solid #1c184c;
}

.section .section-container .human-cards .human-card .human-card-icon {
  border-radius: 100%;
  padding: 10px;
  border: 1px solid #fff;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin: 0 auto 1.2rem auto;
}

.section .section-container .human-cards .human-card .human-card-title {
  font-weight: bold;
  margin: 1rem 0;
}

.section .section-container .human-cards .human-card .human-card-list {
  margin: 1rem 0;
}

.section .section-container .human-cards .human-card .human-card-list-item {
  font-size: 0.8rem;
  margin: 0.8rem 0;
}

@media only screen and (max-width: 1024px) and (min-device-width: 280px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .section .section-container .human-cards {
    grid-template-columns: repeat(1, 90%);
  }
}

.section .section-container .footer-title {
  font-family: "Saira Stencil One", cursive;
  text-align: center !important;
  margin: 2rem 0;
}

.section.section-footer {
  margin-top: 8rem;
}

.section.section-footer video {
  display: block;
}

.section.section-footer .bottom-section {
  padding: 4rem 0;
  background-color: #e89f4d3f;
  /* background-image: url("../images/orange-surface.png"); */
  margin-top: 8rem;
  border-top: 2px solid rgba(255, 255, 255, 0.193);
}

.section.section-footer .bottom-section .footer-section-container {
  max-width: 1600px;
  margin: 0 auto;
}

.section.section-footer .bottom-section .section-container {
  display: flex;
}

.section.section-footer .bottom-section .section-container .left-footer {
  padding: 2rem 0;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top {
  display: flex;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .left-footer-block {
  width: 60%;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block {
  width: 40%;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .top-section-footer-right {
  display: flex;
}
.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .top-section-footer-right
  .help-section {
  align-self: center;
  margin-left: 2rem;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .top-section-footer-right
  .help-section
  .title {
  font-size: 20px;
  border-left: 4px solid #fff;
  padding: 0.3rem;
  padding-left: 0.2rem;
  margin-bottom: 1rem;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .top-section-footer-right
  .help-section
  a {
  text-align: center;
  color: #fff;
  margin-left: 2rem;
  font-weight: bold;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .website-logo {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  height: 150px;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .website-logo
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .right-footer-blocks {
  margin: 1rem 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: flex-start;
  grid-gap: 30px;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .right-footer-blocks
  .footer-block
  .footer-link {
  margin: 0.8rem;
}

section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .right-footer-blocks
  .footer-block
  .footer-link
  a {
  color: #fff;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .right-footer-blocks {
  display: flex;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .footer-block-list {
  font-weight: bold;

  margin: 2rem 0;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .footer-block-list
  .footer-block-list-title {
  font-weight: bold;
  margin: 0.8rem 0;
}
.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .right-footer-block
  .footer-block-list
  li {
  font-size: 14px;
  line-height: 1.6rem;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.686);
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-bottom {
  display: flex;
  height: 100%;
  margin-top: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-bottom
  .social-links {
  display: flex;

  align-items: center;
  margin: 1rem auto;

  justify-content: space-around;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-bottom
  .copyright {
  font-size: 12px;
  text-align: center;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-bottom
  .social-links
  .social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 0.8rem;
  background-color: #fff;
  color: #130523;
  width: 20px;
  margin: 0 0.6rem;
  height: 20px;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-bottom
  .social-links
  .social-link
  a {
  background-color: #fff;
  color: #130523;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-bottom
  .social-links
  .social-link
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .left-footer-block
  .footer-block-title {
  font-size: 2.2rem;
  font-weight: bold;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .left-footer-block
  .footer-block-title.play-title {
  font-size: 2.2rem;
  font-weight: bold;
  margin: 1rem 0;
  font-family: "Saira Stencil One", cursive;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .left-footer-block
  .footer-block-paragraph {
  line-height: 1.6rem;
  margin: 1rem 0;
  text-align: justify;
  color: rgba(255, 255, 255, 0.686);
  width: 69%;
  font-size: 14px;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .left-footer-block
  .footer-block-list {
  margin: 2rem 0;
}

.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .left-footer-block
  .footer-block-list
  .footer-block-list-title {
  font-weight: bold;
  margin: 0.8rem 0;
}
.section.section-footer
  .bottom-section
  .footer-section-container
  .footer-blocks
  .footer-blocks-top
  .left-footer-block
  .footer-block-list
  li {
  line-height: 1.6rem;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.686);
}

@media only screen and (max-width: 1366px) {
  .section.section-footer .bottom-section {
    padding: 2rem 1rem;
  }
}

@media only screen and (max-width: 1024px) and (min-device-width: 280px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top {
    flex-direction: column;
  }

  .section.section-footer {
    margin-top: 0 !important;
  }

  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top
    .left-footer-block {
    width: 100%;
  }

  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top
    .right-footer-block {
    width: 100%;
  }

  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top
    .left-footer-block
    .footer-block-paragraph {
    width: 100%;
  }

  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top
    .right-footer-block
    .top-section-footer-right {
    flex-direction: column;
  }

  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top
    .right-footer-block
    .top-section-footer-right
    .help-section {
    justify-content: center;
    margin: 1rem auto;
  }

  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top
    .right-footer-block
    .website-logo {
    margin: 0 auto;
  }

  .section.section-footer
    .bottom-section
    .footer-section-container
    .footer-blocks
    .footer-blocks-top
    .left-footer-block
    .footer-block-title.play-title {
    text-align: center;
    font-size: 1.5rem;
    margin: 2rem 0;
  }
}

.egypt-section {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  align-items: center;
  border-radius: 100%;
  background-image: url(/static/media/pyramidsbg.e792212b.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 4rem 0;
  justify-content: center;
  position: relative;
}

.egypt-section .egypt-concepts {
  display: flex;
  height: auto;

  justify-content: center;
}

.egypt-section .egypt-concepts .egypt-concept {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin: 1rem 5rem 1rem 5rem;
  justify-content: space-between;
}

.egypt-section .egypt-concepts .egypt-concept .right-concept {
  width: 400px;
  height: 100%;
  background-color: "#fff";
  background-image: "linear-gradient(43deg,#fff 0%,#fff 15%,#fff 100%)";
  border-radius: "100%";
  box-shadow: "#fff 10px -2px 110px, #4158d0 0 -10px 20px,5px 5px 15px 5px rgba(0, 0, 0, 0)";

  -webkit-transform: scale(0.75) rotateY(-30deg) rotateX(45deg)
    translateZ(4.5rem);
  transform: scale(0.75) rotateY(-30deg) rotateX(45deg) translateZ(4.5rem);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: 0.6s ease transform;
}

.egypt-section .egypt-concepts .egypt-concept .right-concept.left {
  -webkit-transform: scale(0.75) rotateY(30deg) rotateX(45deg)
    translateZ(4.5rem);
  transform: scale(0.75) rotateY(30deg) rotateX(45deg) translateZ(4.5rem);
}

.egypt-section .egypt-concepts .egypt-concept .right-concept:hover {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.egypt-section .egypt-concepts .egypt-concept .right-concept::before {
  -webkit-transform: translateZ(4rem);
  transform: translateZ(4rem);
}

.egypt-section .egypt-concepts .egypt-concept .right-concept::before:hover {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.egypt-section .egypt-concepts .egypt-concept .right-concept::after {
  -webkit-transform: translateZ(-4rem);
  transform: translateZ(-4rem);
}

.egypt-section .egypt-concepts .egypt-concept .right-concept::after:hover {
  -webkit-transform: translateZ(-1px);
  transform: translateZ(-1px);
}
/*# sourceMappingURL=deneme.css.map */

.egypt-section .egypt-concepts .egypt-concept .right-concept img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 50px;
}

@media only screen and (max-width: 1024px) and (min-device-width: 280px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .egypt-section .egypt-concepts {
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: center;
  }
  .egypt-section {
    background-image: none;
  }

  .egypt-section .section-title {
    font-size: 2rem;
  }

  .egypt-section .egypt-concepts .egypt-concept {
    margin: 1rem 0;
  }

  .egypt-section .egypt-concepts .egypt-concept .right-concept,
  .egypt-section .egypt-concepts .egypt-concept .right-concept.left {
    -webkit-transform: scale(0.75) rotateY(0deg) rotateX(0deg) translateZ(0);
    transform: scale(0.75) rotateY(0deg) rotateX(0deg) translateZ(0);
    transition: none;
  }
}

.mint-section {
  background-color: #2b6688;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1rem;
}

.amount {
  font-family: "Stalinist One", cursive;
  font-size: 2.5rem;
  color: #fff;
  text-align: center;
}

.contract {
  text-align: center;
  margin: 1rem 0;
  color: #fff;
}

.contract .contract-title {
  font-weight: bold;
  margin: 0.6rem 0;
}

.contract .contract-link a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.549);
}

.pricing {
  margin: 5rem 0;
  text-align: center;
  color: #ffff;
  font-size: 3rem;
  /*font-family: "Stalinist One", cursive;*/
}

.pricing .item-amount {
  color: #19113d;
  font-family: "Press Start 2P", cursive;
}

.pricing .cost {
  color: #19113d;
  font-family: "Press Start 2P", cursive;
}

.pricing .pricing-note {
  font-size: 15px;
  margin: 0.2rem 0;
  font-family: "Press Start 2P", cursive;
}

.connection {
  text-align: center;
}

.connection .connection-title {
  color: #fff;
  font-size: 20px;
}

.connection .connection-title span {
  font-weight: 900;
  color: #19113d;
}

.connection .connect-btn {
  padding: 1rem 1.5rem;
  margin: 1.4rem 0;
  border-radius: 10px;
  border: none;
  background: none;
  cursor: pointer;
  background-color: #19113d;
  color: #fff;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  padding: 1rem;
}

.error .error-message {
  color: #eb252a;
}

.brief-informations {
  margin: 3rem 0;
  display: grid;
  grid-template-columns: repeat(3, 400px);
  grid-gap: 30px;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
}

.brief-informations .information {
  background-color: #fff;
  padding: 1rem;
  background-image: url(/static/media/wave.fa7c1dcc.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100%;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 24px 50px rgb(0 0 0 / 15%);
}

.brief-informations .information .info-t {
  margin: 0 auto;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  color: #19113d;
  font-weight: bold;
}

.brief-informations .information .info-p {
  color: #19113d;
  width: 70%;
  margin: 0 auto;
  line-height: 1.6rem;
}

.brief-informations .information .info-logo {
  margin: 0 auto;
  width: 80px;
  height: 80px;
  margin-bottom: 2rem;
}

.brief-informations .information .info-logo i {
  margin: 0 auto;
  font-size: 4rem;
  margin-bottom: 2rem;
  color: #19113d;
}

.brief-informations .information .info-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feedback {
  margin: 1rem 0;
  color: #fff;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: center;
}

.buttons .mint-amount {
  font-family: "Press Start 2P", cursive;
  background-color: #2b6688;
  align-self: center;
  font-size: 3rem;
  color: #fff;
}

.buttons .increase-btn {
  padding: 0.6rem 1rem;
  margin: 1.4rem 0;
  border-radius: 10px;
  width: 50px;
  font-family: "Press Start 2P", cursive;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  border: none;
  margin-left: 1rem;
  background: none;
  cursor: pointer;
  background-color: transparent;
  border: 4px solid #19113d;
  color: #19113d;
  font-size: 1.5rem;
}

.buttons .decrease-btn {
  padding: 0.6rem 1rem;
  margin: 1.4rem 0;
  font-family: "Press Start 2P", cursive;
  border-radius: 10px;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  border: none;
  margin-right: 1rem;
  background: none;
  cursor: pointer;
  background-color: transparent;
  border: 4px solid #19113d;
  color: #19113d;
  font-size: 1.5rem;
}

.buy-btn {
  padding: 1rem 1.5rem;
  margin: 1.4rem 0;
  border-radius: 10px;
  font-family: "Press Start 2P", cursive;
  border: none;
  background: none;
  cursor: pointer;
  background-color: #19113d;
  color: #fff;
  box-shadow: 0 24px 50px rgb(0 0 0 / 15%);
}

@media only screen and (max-width: 1024px) and (min-device-width: 280px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .brief-informations {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 80px;
  }
  .brief-informations .information {
    background-image: none;
  }
  .pricing {
    font-size: 2em;
  }
}

body {
  margin: 0;
  font-family: "Gothic A1", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  /* min-height: 15000px; */
  overflow-x: hidden;
  background-color: #130523;
  box-sizing: border-box;
}
video {
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

